.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgray;
  background: #f9f9f9;
  margin-block: 20px;
  border-radius: 6px;
  padding: 5px 10px;
}
.main p {
  margin: 0;
}
.checkbox {
}
.captchaBox {
  width: min-content;
  text-align: center;
}
.captchaBox p {
  margin: 0;
  white-space: nowrap;
  font-size: 10px;
  margin-top: -10px;
}
