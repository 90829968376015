.auth {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.auth__row {
  flex: 1 1;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.auth .image-col {
  /* background: var(--color-wyreBlue-100) url(/static/media/auth.b1df74e4a54d95d8f79f.png) no-repeat center; */
  background-size: 80%;
}

.auth__row [class*="col-"] {
  align-items: center;
  display: flex;
  justify-content: center;
}
.auth__logo-wrapper {
  align-items: center;
  display: flex;
  left: 32px;
  position: absolute;
  top: 32px;
  z-index: var(--zIndex-lowest);
}
.auth .btn-support {
  bottom: 32px;
  left: 32px;
  position: absolute;
  z-index: var(--zIndex-lowest);
}

a,
a:hover {
  color: var(--color-black);
}

a {
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  justify-self: flex-start;
  padding: 0 var(--spacing-2xs);
}

[role="button"],
a,
area,
button,
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
[role="button"],
a,
area,
button,
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
.auth__logo-wrapper .logo {
  height: 32px;
  width: 104px;
}

.error {
  color: red;
  font-size: 13px;
}

.dashboardIcon > svg {
  background-color: #444343;
  border-radius: 50%;
  color: #cdc8c8;
  padding: 4px;
}

.reactIcons > svg {
  padding: 6px;
  width: 24px;
  height: 24px;
}

.sidenav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 16px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.tdStyle {
  width: fit-content;
  background-color: orange;
  color: aliceblue;
}

.card {
  width: 30%;
  margin-inline: auto;
}

.mainTimeOut {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.inavlidSession {
  margin-bottom: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.sessionTimeoutBody {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-block: 70px;
}
.sidebarMain {
  min-width: 250px;
  width: fit-content;
  height: 100vh;
  box-shadow: 0px 0px 5px lightgrey;
  position: absolute;
  top: 0;
  left: 0;
  background: #171717;
  color: white;
  height: 100vh;
  overflow: auto;
}
.sidebarMain .MuiBackdrop-root {
  opacity: 10% !important;
}
.bitcoinIcon {
  background: #ff9800;
  border-radius: 50%;
  color: #fff;
  width: 32px !important;
  height: 32px !important;
  font-size: 16px;
}

.sideModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 11px;
}

.actionBtn {
  color: #cdc8c8;
}
.insufficientBalance {
  font-size: 14px;
}
.insufficientBalance > svg {
  color: white;
  background: red;
  border-radius: 50%;
  font-size: 12px;
}
.activityRow {
  line-height: 62px;
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
