.main {
  block-size: 961px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 1790px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 960px 480.5px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 960px 480.5px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}

.main1 {
  block-size: 961px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 1790px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  line-height: 22.001px;
  margin-left: auto;
  margin-right: auto;
  min-block-size: 961px;
  min-height: 961px;
  outline-color: rgba(0, 0, 0, 0.85);
  padding-inline-end: 15px;
  padding-inline-start: 15px;
  padding-left: 15px;
  padding-right: 15px;
  perspective-origin: 960px 480.5px;
  position: relative;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 960px 480.5px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main2 {
  block-size: 961px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-wrap: wrap;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 1790px;
  line-height: 22.001px;
  margin-inline-end: -15px;
  margin-inline-start: -15px;
  margin-left: -15px;
  margin-right: -15px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 960px 480.5px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 960px 480.5px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main3 {
  align-items: center;
  background-color: rgb(230, 238, 254);
  background-image: url(https://dashv2.sendwyre.com/static/media/auth.b1df74e4a54d95d8f79f.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 80%;
  block-size: 961px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-basis: 50%;
  flex-shrink: 0;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 960px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  justify-content: center;
  left: 0px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  max-inline-size: 50%;
  max-width: 50%;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  padding-inline-end: 15px;
  padding-inline-start: 15px;
  padding-left: 15px;
  padding-right: 15px;
  perspective-origin: 480px 480.5px;
  position: relative;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 480px 480.5px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
  height: 961px;
}
.main4 {
  align-items: center;
  block-size: 34px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  bottom: 895px;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 113.5px;
  inset-block-end: 895px;
  inset-block-start: 32px;
  inset-inline-end: 814.5px;
  inset-inline-start: 32px;
  left: 32px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 56.75px 17px;
  position: absolute;
  right: 814.5px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  top: 32px;
  transform-origin: 56.75px 17px;
  z-index: 1;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main5 {
  block-size: 34px;
  border-block-end-color: rgba(0, 0, 0, 0);
  border-block-end-style: solid;
  border-block-end-width: 1px;
  border-block-start-color: rgba(0, 0, 0, 0);
  border-block-start-style: solid;
  border-block-start-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
  border-inline-end-color: rgba(0, 0, 0, 0);
  border-inline-end-style: solid;
  border-inline-end-width: 1px;
  border-inline-start-color: rgba(0, 0, 0, 0);
  border-inline-start-style: solid;
  border-inline-start-width: 1px;
  border-left-color: rgba(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgba(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 1px;
  border-start-end-radius: 4px;
  border-start-start-radius: 4px;
  border-top-color: rgba(0, 0, 0, 0);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  caret-color: rgb(23, 23, 23);
  color: rgb(23, 23, 23);
  column-rule-color: rgb(23, 23, 23);
  cursor: pointer;
  display: inline-flex;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 113.5px;
  justify-self: flex-start;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgb(23, 23, 23);
  padding-inline-end: 3.75px;
  padding-inline-start: 3.75px;
  padding-left: 3.75px;
  padding-right: 3.75px;
  perspective-origin: 56.75px 17px;
  text-align: left;
  text-decoration: none;
  text-decoration-color: rgb(23, 23, 23);
  text-emphasis-color: rgb(23, 23, 23);
  text-size-adjust: 100%;
  touch-action: manipulation;
  transform-origin: 56.75px 17px;
  transition-duration: 0.3s;
  transition-property: color;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgb(23, 23, 23);
  -webkit-text-stroke-color: rgb(23, 23, 23);
}
.main6 {
  block-size: 32px;
  border-block-end-color: rgb(23, 23, 23);
  border-block-start-color: rgb(23, 23, 23);
  border-bottom-color: rgb(23, 23, 23);
  border-inline-end-color: rgb(23, 23, 23);
  border-inline-start-color: rgb(23, 23, 23);
  border-left-color: rgb(23, 23, 23);
  border-right-color: rgb(23, 23, 23);
  border-top-color: rgb(23, 23, 23);
  box-sizing: border-box;
  caret-color: rgb(23, 23, 23);
  color: rgb(23, 23, 23);
  column-rule-color: rgb(23, 23, 23);
  cursor: pointer;
  display: flex;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 104px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgb(23, 23, 23);
  perspective-origin: 52px 16px;
  text-align: left;
  text-decoration: none solid rgb(23, 23, 23);
  text-decoration-color: rgb(23, 23, 23);
  text-emphasis-color: rgb(23, 23, 23);
  text-size-adjust: 100%;
  transform-origin: 52px 16px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgb(23, 23, 23);
  -webkit-text-stroke-color: rgb(23, 23, 23);
  height: 32px;
}
.main7 {
  block-size: 33.75px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  bottom: 32px;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 113.125px;
  inset-block-end: 32px;
  inset-block-start: 895.25px;
  inset-inline-end: 814.875px;
  inset-inline-start: 32px;
  left: 32px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 56.5625px 16.875px;
  position: absolute;
  right: 814.875px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  top: 895.25px;
  transform-origin: 56.5625px 16.875px;
  z-index: 1;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main8 {
  border: none;
  background: #fff;
  border-radius: 8px;
  margin-left: 32px;
  padding: 12px;
}
.main9 {
  align-items: center;
  block-size: 961px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-basis: 50%;
  flex-shrink: 0;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 960px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  justify-content: center;
  left: 0px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  max-inline-size: 50%;
  max-width: 50%;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  padding-inline-end: 15px;
  padding-inline-start: 15px;
  padding-left: 15px;
  padding-right: 15px;
  perspective-origin: 480px 480.5px;
  position: relative;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 480px 480.5px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
  height: 961px;
}
.main10 {
  block-size: 616.859px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: grid;
  flex-shrink: 0;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  grid-template-columns: 440px;
  grid-template-rows: 92.25px 430.859px 33.75px;
  inline-size: 440px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  max-inline-size: 440px;
  max-width: 440px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 220px 308.422px;
  row-gap: 30px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 220px 308.43px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
  width: 100%;
}
.main11 {
  block-size: 36px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-bottom-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  border-left-color: rgb(51, 51, 51);
  border-right-color: rgb(51, 51, 51);
  border-top-color: rgb(51, 51, 51);
  box-sizing: border-box;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  font-family: Favorit Extended, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 30px;
  font-weight: 400;
  inline-size: 440px;
  line-height: 36px;
  margin-block-end: 11.25px;
  margin-block-start: 45px;
  margin-bottom: 11.25px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgb(51, 51, 51);
  perspective-origin: 220px 18px;
  text-align: center;
  text-decoration: none solid rgb(51, 51, 51);
  text-decoration-color: rgb(51, 51, 51);
  text-emphasis-color: rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 220px 18px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgb(51, 51, 51);
  -webkit-text-stroke-color: rgb(51, 51, 51);
}
.main12 {
  block-size: 430.859px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-end-end-radius: 6px;
  border-end-start-radius: 6px;
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-start-end-radius: 6px;
  border-start-start-radius: 6px;
  border-top-color: rgba(0, 0, 0, 0.85);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: rgba(192, 192, 201, 0.25) 0px 12px 24px 0px;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 440px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  padding-block-end: 22.5px;
  padding-block-start: 22.5px;
  padding-bottom: 22.5px;
  padding-inline-end: 22.5px;
  padding-inline-start: 22.5px;
  padding-left: 22.5px;
  padding-right: 22.5px;
  padding-top: 22.5px;
  perspective-origin: 220px 215.422px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 220px 215.43px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main13 {
  block-size: 385.859px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 395px;
  line-height: 22.001px;
  margin-block-end: 0px;
  margin-bottom: 0px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 197.5px 192.922px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 197.5px 192.93px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main14 {
  block-size: 385.859px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: grid;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  grid-template-columns: 395px;
  grid-template-rows: 74.7188px 74.7188px 78px 63.75px 34.6719px;
  inline-size: 395px;
  line-height: 22.001px;
  margin-bottom: 0px !important;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 197.5px 192.922px;
  row-gap: 15px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 197.5px 192.93px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main15 {
  block-size: 74.7188px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 395px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 197.5px 37.3594px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 197.5px 37.3594px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main31 {
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 13.125px;
  inline-size: 100%;
  line-height: 20.6259px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 0px 0px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main16 {
  block-size: 74.7188px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 395px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 197.5px 37.3594px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 197.5px 37.3594px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main17 {
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 13.125px;
  inline-size: 100%;
  line-height: 20.6259px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 0px 0px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 0px 0px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main18 {
  block-size: 78px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 395px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 197.5px 39px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 197.5px 39px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main19 {
  block-size: 78px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 395px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 197.5px 39px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 197.5px 39px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main20 {
  block-size: 78px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 304px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 152px 39px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 152px 39px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
  width: 304px;
  height: 78px;
}
.main21 {
  block-size: 85px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 304px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 152px 42.5px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 152px 42.5px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main22 {
  block-size: 41.25px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 395px;
  line-height: 22.001px;
  margin-block-start: 22.5px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1.5rem !important;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 197.5px 20.625px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 197.5px 20.625px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main23 {
  block-size: 41.25px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: block;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 395px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 197.5px 20.625px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 197.5px 20.625px;
  user-select: none;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main24 {
  block-size: 19.6719px;
  border-block-end-color: rgba(0, 0, 0, 0);
  border-block-end-style: solid;
  border-block-end-width: 1px;
  border-block-start-color: rgba(0, 0, 0, 0);
  border-block-start-style: solid;
  border-block-start-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
  border-inline-end-color: rgba(0, 0, 0, 0);
  border-inline-end-style: solid;
  border-inline-end-width: 1px;
  border-inline-start-color: rgba(0, 0, 0, 0);
  border-inline-start-style: solid;
  border-inline-start-width: 1px;
  border-left-color: rgba(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgba(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 1px;
  border-start-end-radius: 4px;
  border-start-start-radius: 4px;
  border-top-color: rgba(0, 0, 0, 0);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  cursor: pointer;
  display: inline-flex;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 11.25px;
  inline-size: 132.094px;
  justify-self: flex-start;
  line-height: 17.6794px;
  margin-block-start: 15px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1rem !important;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgb(51, 51, 51);
  padding-inline-end: 3.75px;
  padding-inline-start: 3.75px;
  padding-left: 3.75px;
  padding-right: 3.75px;
  perspective-origin: 66.0469px 9.82812px;
  text-align: center;
  text-decoration: underline solid rgb(51, 51, 51);
  text-decoration-color: rgb(51, 51, 51);
  text-decoration-line: underline;
  text-emphasis-color: rgb(51, 51, 51);
  text-size-adjust: 100%;
  touch-action: manipulation;
  transform-origin: 66.0469px 9.83594px;
  transition-duration: 0.3s;
  transition-property: color;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-decorations-in-effect: underline;
  -webkit-text-fill-color: rgb(51, 51, 51);
  -webkit-text-stroke-color: rgb(51, 51, 51);
}
.main25 {
  align-items: center;
  block-size: 33.75px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 440px;
  justify-content: space-around;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 220px 16.875px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 220px 16.875px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main26 {
  block-size: 20.625px;
  border-block-end-color: rgb(51, 51, 51);
  border-block-start-color: rgb(51, 51, 51);
  border-bottom-color: rgb(51, 51, 51);
  border-inline-end-color: rgb(51, 51, 51);
  border-inline-start-color: rgb(51, 51, 51);
  border-left-color: rgb(51, 51, 51);
  border-right-color: rgb(51, 51, 51);
  border-top-color: rgb(51, 51, 51);
  box-sizing: border-box;
  caret-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
  column-rule-color: rgb(51, 51, 51);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 13.125px;
  inline-size: 148.281px;
  line-height: 20.6259px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  margin-bottom: 0px;
  margin-inline-end: 15px;
  margin-left: 0;
  margin-right: 1rem !important;
  margin-top: 0px;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgb(51, 51, 51);
  perspective-origin: 74.1406px 10.3125px;
  text-align: left;
  text-decoration: none solid rgb(51, 51, 51);
  text-decoration-color: rgb(51, 51, 51);
  text-emphasis-color: rgb(51, 51, 51);
  text-size-adjust: 100%;
  transform-origin: 74.1406px 10.3125px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgb(51, 51, 51);
  -webkit-text-stroke-color: rgb(51, 51, 51);
}
.main27 {
  block-size: 33.75px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  display: block;
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 113.125px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 56.5625px 16.875px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 56.5625px 16.875px;
  user-select: none;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
}
.main28 {
  block-size: 0px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 1890px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  min-block-size: auto;
  min-height: auto;
  min-inline-size: auto;
  min-width: auto;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 945px 0px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  transform-origin: 945px 0px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
  height: 0px;
}
.main29 {
  block-size: 961px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 1790px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 0px;
  left: 0px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 960px 480.5px;
  position: fixed;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 960px 480.5px;
  z-index: 9999;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
  width: 1920px;
  height: 961px;
}
.main30 {
  block-size: 961px;
  border-block-end-color: rgba(0, 0, 0, 0.85);
  border-block-start-color: rgba(0, 0, 0, 0.85);
  border-bottom-color: rgba(0, 0, 0, 0.85);
  border-inline-end-color: rgba(0, 0, 0, 0.85);
  border-inline-start-color: rgba(0, 0, 0, 0.85);
  border-left-color: rgba(0, 0, 0, 0.85);
  border-right-color: rgba(0, 0, 0, 0.85);
  border-top-color: rgba(0, 0, 0, 0.85);
  bottom: 0px;
  box-sizing: border-box;
  caret-color: rgba(0, 0, 0, 0.85);
  color: rgba(0, 0, 0, 0.85);
  column-rule-color: rgba(0, 0, 0, 0.85);
  font-family: FK Grotesk, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  inline-size: 960px;
  inset-block-end: 0px;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-inline-start: 960px;
  left: 960px;
  line-height: 22.001px;
  margin-left: 0;
  margin-right: 0;
  opacity: 0.5;
  outline-color: rgba(0, 0, 0, 0.85);
  perspective-origin: 480px 480.5px;
  position: fixed;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgba(0, 0, 0, 0.85);
  text-decoration-color: rgba(0, 0, 0, 0.85);
  text-emphasis-color: rgba(0, 0, 0, 0.85);
  text-size-adjust: 100%;
  top: 0px;
  transform-origin: 480px 480.5px;
  -webkit-locale: zh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.85);
  width: 960px;
  height: 961px;
}

.inputLabel {
}

.rightBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: #fffffa;
}

.formWrapper {
  width: 450px;
  margin-inline: auto;
  background-color: #fff;
  padding: 48px 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 50px #e7e7e7;
}
.submitBtn {
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
}
.form {
  text-align: left;
}

.inputLabel {
}

.forgotPassword {
  margin-block: 10px;
}

.SignUp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  margin: 24px auto;
}

.SignUp button {
  border: none;
  background-color: #fff;
  padding: 8px 12px;
  box-shadow: 0 3px 1px -1px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.sideModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 11px;
}
