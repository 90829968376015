.main {
}
.input {
  width: -webkit-fill-available;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 3px 1px -1px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: none;
}

.input::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
